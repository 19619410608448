<template>
  <div :class="[$style.title, $style[position]]">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
.title {
  margin: 0 0 1rem;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.625rem;

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }
}
</style>
