<template>
  <div>
    <div :class="$style.promos">
      <div>
        <Title text="Промокоды" position="right" />
      </div>
      <div :class="$style.promos__header">
        <DefaultSelect
          v-model="filter.active"
          :items="list"
          :font-size="'12px'"
          placeholder="Выбрать активность"
          :style="{
            marginRight: '10px',
            width: '11.125rem',
            borderRadius: '6px',
            height: '2.625rem',
            alignItems: 'center',
            display: 'flex'
          }"
          @input="(val) => inputSelect(val, 'active')"
        />
        <DefaultSelect
          v-model="filter.tariffId"
          :items="constants.tariffs"
          :font-size="'12px'"
          :style="{
            marginRight: '10px',
            width: '11.125rem',
            borderRadius: '6px',
            height: '2.625rem',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }"
          placeholder="Выбрать Тариф"
          @input="(val) => inputSelect(val, 'tariffId')"
        />
        <DefaultSelect
          v-model="filter.createdById"
          :items="employees"
          :font-size="'12px'"
          :style="{
            marginRight: '10px',
            width: '12.125rem',
            borderRadius: '6px',
            height: '2.625rem',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }"
          placeholder="Выбрать ответственного"
          @input="(val) => inputSelect(val, 'createdById')"
        />
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <div
                style="display: grid"
                @click="sortPromos(headerColumn.sortValue)"
                v-if="headerColumn.sortValue"
              >
                <img src="../assets/icons/Vector.svg" alt="" width="10" height="10" />
                <img src="../assets/icons/Vector-bottom.svg" alt="" width="10" height="10" />
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.table__content">
          <div v-for="promo in totalItems" :key="promo.id">
            <div :class="$style.content">
              <div>{{ promo.id }}</div>
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                {{ promo.code }}
                <img
                  v-if="promo.active && lookDate(promo)"
                  src="../assets/icons/clock.svg"
                  alt="clock"
                />
                <img v-if="!promo.active" src="../assets/icons/cross-circle.svg" alt="clock" />
                <img
                  v-if="promo.active && !lookDate(promo)"
                  src="../assets/icons/tick-circle.svg"
                  alt="clock"
                />
              </div>
              <div>{{ promo.usages }}</div>
              <div>{{ moment(promo.start).add(-3, 'H').format('DD-MM-YYYY HH:mm') }}</div>
              <div>{{ moment(promo.end).add(-3, 'H').format('DD-MM-YYYY HH:mm') }}</div>
              <div>{{ takeTariffs(promo.tariffs) }}</div>
              <div style="display: flex; flex-direction: row">
                <span style="width: 200px">{{ subString(promo.description) }}</span
                ><img
                  style="display: flex"
                  src="../assets/icons/gear.svg"
                  alt="gear"
                  @click="goToPromoDetails(promo)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.promos__footer">
      <div>
        <pagination
          :pageCount="promoPageCount"
          :prevText="''"
          :click-handler="(page) => onPromoPageChange(page)"
          :nextText="''"
          :containerClass="'pagination'"
          :page-class="'page'"
          :initial-page="this.$route.query.page - 1"
        />
      </div>
      <div v-if="isAdmin" :class="$style.promos__footer_button">
        <button @click="onNewPromoButtonClick">Добавить</button>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import promos from '@/api/promos'
import employees from '@/api/employees'

import Title from '@/basic/Title.vue'

import paginationMixin from '@/mixins/pagination.mixin'

import 'vue2-datepicker/index.css'
import { mapGetters, mapMutations } from 'vuex'
import DefaultSelect from '@/basic/DefaultSelect.vue'

export default {
  name: 'Promos',
  components: {
    DefaultSelect,
    Title
  },
  data() {
    return {
      promos: [],
      filter: {},
      filterValue: '',
      sortMethod: 'desc',
      sortBy: 'id',
      initialPage: 1,
      headerColumns: [
        {
          id: 1,
          title: 'ID',
          sortValue: 'id'
        },
        {
          id: 2,
          title: 'Код',
          sortValue: 'code'
        },
        {
          id: 3,
          title: 'Активаций',
          sortValue: 'usages'
        },
        {
          id: 4,
          title: 'Активен с',
          sortValue: 'start'
        },
        {
          id: 5,
          title: 'Активен до',
          sortValue: 'end'
        },

        {
          id: 6,
          title: 'Применяемость'
        },
        {
          id: 7,
          title: 'Описание'
        }
      ],
      list: [
        { id: 1, name: 'Активный' },
        { id: 0, name: 'Не активный' }
      ],
      isActive: false,
      hasTariff: null,
      employees: []
    }
  },
  mixins: [paginationMixin],
  computed: {
    ...mapGetters(['constants', 'isAdmin']),
    moment() {
      return Moment
    }
  },
  created() {
    for (const [key, value] of Object.entries(this.constants)) {
      if (this[key]) {
        this[key] = value
      }
    }
  },
  async mounted() {
    if (this.isAdmin) {
      const res = (await employees.getEmployees()).data.result.rows
      this.employees = res.map((el) => {
        return { id: el.id, name: el.middleName + ' ' + el.firstName + ' ' + el.lastName }
      })
    }
    const page = parseInt(this.$route.query.page)
    const { data } = await promos.getPromos({
      pagination: {
        offset: page * 10 - 10,
        limit: 10
      },
      scan: {
        fields: [],
        value: this.filterValue
      },
      order: {
        field: 'id',
        by: this.sortMethod
      }
    })
    this.promos = data.result.rows
    this.setupPromoPagination(this.promos, data.result.count)
  },
  watch: {
    async filter() {
      await this.getPromos()
    }
  },
  methods: {
    ...mapMutations(['setPromoDetails']),
    lookDate(promo) {
      return this.moment().isBefore(promo.start)
    },
    subString(description) {
      return (
        (description &&
          (description.length <= 20 ? description : description.substring(0, 20))) ||
        ''
      )
    },
    takeTariffs(tariffs) {
      const str = JSON.stringify(tariffs.map((e) => e.name))
        .replace(/[[\]{}"]/g, '')
        .replace(/,/g, ', ')
      return str.length <= 20 ? str : str.substring(0, 17) + ' ...'
    },
    async getPromos() {
      const page = parseInt(this.$route.query.page)
      const dataFilter = {
        pagination: {
          offset: page * 10 - 10,
          limit: 10
        },
        scan: {
          fields: [],
          value: this.filterValue
        },
        order: {
          field: this.sortBy,
          by: this.sortMethod
        }
      }
      dataFilter.filter = {
        ...dataFilter.filter,
        ...this.filter
      }

      const { data } = await promos.getPromos(dataFilter)
      this.promos = data.result.rows
      this.setupPromoPagination(this.promos, data.result.count)
    },
    goToPromoDetails(promo) {
      this.$store.dispatch('openModal', ['PromoDetails', { promo: promo }])
    },
    async onPromoPageChange(page) {
      await this.$router.push(`?page=${page}`)
      await this.getPromos()
    },
    onNewPromoButtonClick() {
      this.$store.dispatch('openModal', ['Promo'])
    },
    inputSelect(val, key) {
      if (!val) {
        delete this.filter[key]
      } else {
        this.filter[key] = val.id
      }
      this.getPromos()
    },
    async sortPromos(sortBy) {
      this.sortBy = sortBy
      this.toggleSortMethod()
      await this.getPromos()
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $table-border;
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #e7e4e4;
    opacity: 0.8;
  }
  &:last-child {
    border-bottom: none;
  }

  div {
    &:first-child {
      width: 6%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }
    &:nth-child(2) {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }

    &:nth-child(3) {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }

    &:nth-child(4) {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }
    &:nth-child(5) {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }
    &:nth-child(6) {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
      border-right: 1px solid $table-border;
    }
    &:last-child {
      width: 15.67%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.7rem 1rem;
    }
  }
}
.promos {
  &__header {
    display: flex;
    align-items: flex-start;
    margin: 0 0 2rem;
    &_wrapper {
      display: flex;
      align-items: center;

      p {
        font-size: 0.75rem;
      }
    }
  }

  .table {
    display: table;
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      font-size: 0.875rem;
      font-weight: 500;
      font-family: Roboto, serif;
      line-height: 1.026rem;
      width: 100%;
      &_row {
        display: flex;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }
      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.625rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
        }

        &:first-child {
          width: 6%;
          display: flex;
        }

        &:nth-child(2) {
          width: 15.67%;
          display: flex;
        }

        &:nth-child(3) {
          width: 15.67%;
          display: flex;
        }

        &:nth-child(4) {
          width: 15.67%;
          display: flex;
        }
        &:nth-child(5) {
          width: 15.67%;
          display: flex;
        }
        &:nth-child(6) {
          width: 15.67%;
          display: flex;
        }
        &:last-child {
          width: 15.67%;
          display: flex;
          border: none;
        }
      }
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background: #e7e4e4;

          opacity: 0.8;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    &_button {
      margin-top: 30px;
      button {
        padding: 0.75rem 1.5rem;
        background: #f1e6e6;
        border: 1px solid #c4c4c4;
        border-radius: 1rem;
        font-size: 1.125rem;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
